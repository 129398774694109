import React from 'react';
import { useScrollTrigger, Fab, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Define the type for your component props
interface ScrollToTopProps {
  window?: () => Window;
}

const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  const { window } = props;

  // Use the `useScrollTrigger` hook to determine if the page has been scrolled down
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100, // Trigger the button to show after scrolling down 100px
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (event.target as HTMLElement).ownerDocument?.querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" style={{ position: 'fixed', bottom: 65, right: 16 }}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
}

export default ScrollToTop;
