import { Fragment } from "react"

import { showFriendlyTime } from '../../helpers/Utilities';

import { Box, Grid, List, ListItem, ListItemText, Typography, Button  } from "@mui/material"
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function BookClassesList({ date, classItems, user, navigate }: { date: string, classItems: any[], user: any, navigate: any }) {
  return (
    <Fragment>
      {/* Date Header */}
      <Box sx={{ width: '100%', backgroundColor: '#eeeeee', padding: 1, display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        <CalendarMonthOutlinedIcon sx={{ fontSize: 'x-large', marginRight: '5px' }} />
        <Typography sx={{ fontWeight: '500', color: 'black', fontSize: '0.975rem' }}>{date}</Typography>
      </Box>
      
      {/* List of Classes */}
      <List>
        {classItems.map((classItem) => (
          <ListItem sx={{ px: 1 }} key={classItem.id}>
            <ListItemText primary={
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                
                {/* Date and Booking Numbers */}
                <Grid item xs={3} sm={3}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {/* Time */}
                    <Typography sx={{ fontWeight: '500', fontSize: '0.9rem' }}>
                      {showFriendlyTime(classItem.date, user?.dataValues?.timezone)}
                    </Typography>
                    {/* If class full show "Full" button, else show available spaces */}
                    {classItem.spaces_booked === classItem.event_limit ? (
                      <Button variant="contained" color="error" size="small">Full</Button>
                    ) : (
                      <Button variant="contained" size="small" sx={{
                        boxShadow: 'none',
                        '&:hover': {
                          boxShadow: 'none',
                        },
                        '&:active': {
                          boxShadow: 'none',
                        },
                      }}>
                        {classItem.spaces_booked} / {classItem.event_limit}
                      </Button>
                    )}
                  </Box>
                </Grid>

                {/* Class Name and Duration */}
                <Grid item xs={6} sm={6}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '0.95rem' }}>
                    {classItem.name}
                  </Typography>
                  <Typography sx={{ fontSize: '0.9rem' }}>
                    {classItem.duration} Mins
                  </Typography>
                </Grid>

                {/* Book Class Button */}
                <Grid item xs={3} sm={3} display="flex" justifyContent="center">
                  <Button
                    disabled={classItem.spaces_booked === classItem.event_limit}
                    variant="contained"
                    size="small"
                    onClick={() => navigate(`/bookclass/${classItem.id}`)}
                    sx={{ '&:hover': { cursor: 'pointer' } }}
                  >
                    Book
                  </Button>
                </Grid>

              </Grid>
            }/>
          </ListItem>
        ))}
      </List>
    </Fragment>
  );
}