// Libs
import { useState } from 'react';
// Material-UI
import { Box, Button, Card, CardContent  } from '@mui/material';

import DeleteClassTypeConfirmation from '../../components/Dialog/DeleteClassTypeConfirmation';

export default function EventTypeCard({eventType, navigate, sendDeleteRequest}: {eventType: any, navigate: any, sendDeleteRequest: any}) {
  const [isDialogOpen, setDialogOpen] = useState(false);

  // Handler to open the dialog for deleting the class type
  const handleDeleteClick = (eventType: any) => {
    setDialogOpen(true);
  };

  // Handler for closing the dialog without action
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Handler for when user confirms deletion
  const handleConfirmDeletion = () => {
    sendDeleteRequest(eventType);
    setDialogOpen(false);
  };

  return (
    <Card key={eventType.id} sx={{mb: 2}}>
      <Box display="flex" justifyContent="space-between">
        <CardContent>
          <div key={eventType.id}>{eventType.name}</div>
        </CardContent>
        <Box display="flex" gap={1}>
        <Button 
            key={`edit-${eventType.id}`}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              localStorage.setItem('existingEvent', JSON.stringify(eventType));
              navigate('/createclasstype');
            }}>
            Edit
        </Button>
        <Button 
            key={`del-${eventType.id}`}
            size="small"
            variant="contained"
            color="warning"
            onClick={() => handleDeleteClick(eventType)}
            >
            Del
        </Button>
        <DeleteClassTypeConfirmation 
            open={isDialogOpen} 
            onClose={handleCloseDialog} 
            onConfirm={handleConfirmDeletion} 
            message={"Are you sure you want to delete this class type? This will also delete all classes and bookings associated with this class type."}
          />
        </Box>
      </Box>
    </Card>
  )
}