import React from 'react';
import { Box, Button } from '@mui/material';
import { Booking } from '../../types/types';

interface ActiveBookingsButtonProps {
  bookingsData?: [string, Booking[]][];
}

const ActiveBookingsButton: React.FC<ActiveBookingsButtonProps> = ({ bookingsData = [] }) => {
  const getNumberOfBookings = (data: [string, Booking[]][]): number => {
    return data.reduce((total, [, innerArray]) => total + innerArray.length, 0);
  };

  const bookingCount = getNumberOfBookings(bookingsData);

  return (
    <Box marginBottom="15px" textAlign={'center'}>
      <Button variant="contained">
        You have {bookingCount} {bookingCount === 1 ? 'Active Booking' : 'Active Bookings'}
      </Button>
    </Box>
  );
};

export default ActiveBookingsButton;
