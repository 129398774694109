// Libs
import React, { ReactNode, useState, useCallback } from 'react';
// Components
import Loading from '../Alerts/Loading';

interface PullToRefreshProps {
  onRefresh: () => Promise<void>; // The refresh action passed as a prop
  children: ReactNode;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({ onRefresh, children }) => {
  const [pulling, setPulling] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleTouchStart = useCallback((e: TouchEvent) => {
    setStartY(e.touches[0].clientY);
    setCurrentY(e.touches[0].clientY);
    setPulling(true);
  }, []);

  const handleTouchMove = useCallback((e: TouchEvent) => {
    if (e.touches[0].clientY > startY) {
      setCurrentY(e.touches[0].clientY);
    }
  }, [startY]);

  const handleTouchEnd = useCallback(async () => {
    if (pulling && currentY - startY > 150) { // 150px threshold for pull to refresh
      setIsLoading(true);
      await onRefresh(); // Call the onRefresh function passed as prop
      setIsLoading(false);
    }
    setPulling(false);
    setStartY(0);
    setCurrentY(0);
  }, [pulling, currentY, startY, onRefresh]);

  return (
    <div
      onTouchStart={e => handleTouchStart(e as unknown as TouchEvent)}
      onTouchMove={e => handleTouchMove(e as unknown as TouchEvent)}
      onTouchEnd={handleTouchEnd}
      style={{ touchAction: 'none' }} // Prevent the browser default pull-to-refresh and zoom actions
    >
    {isLoading && (
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000 }}>
        <Loading text=""/>
      </div>
      )}
      {children}
    </div>
  );
};

export default PullToRefresh;
