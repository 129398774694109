// Types
import { EventType } from '../types/types';

export const getEventTypeByUserId = async (userId: string) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/events/api/get_event_types`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      Accept: 'application/json',
    },
      body: JSON.stringify({userId}),
    });
    const json = await response.json();
    const data = json.eventTypes as EventType[];
    return data
};

const getClubId = async (clubName: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/clubs/api/get_club_id`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({clubName}),
  });
  const json = await response.json();
  const data = json as  {clubId: string};
  return data.clubId;
}

export const getEventTypeByClubName = async (clubName: string) => {
  // First get the id of the club using clubName
  const userId = await getClubId(clubName);

  const url = `${process.env.REACT_APP_API_ENDPOINT}/events/api/get_event_types`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({userId}),
  });
  const json = await response.json();
  const data = json.eventTypes as EventType[];
  return data
};

export const editEventType = async (data: any) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/events/api/edit_event_type`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      Accept: 'application/json',
    },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
};

export const deleteEventType = async (data: any) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/events/api/delete_event_type`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
};

export const createEventType = async (data: any) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/events/api/new_event_type`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      Accept: 'application/json',
    },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
};