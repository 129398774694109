import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AlertProvider } from './AlertContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ServiceWorkerUpdate from './components/Alerts/ServiceWorkerUpdate';

import Home from './pages/Home';
import UserLandingPage from './pages/UserLandingPage';
import ClubLandingPage from './pages/ClubLandingPage';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Profile from './pages/Profile';
import ClubMembers from './pages/ClubMembers';
import ManageFamily from './pages/ManageFamily';
import SearchClasses from './pages/SearchClasses';
import BookClasses from './pages/BookClasses';
import BookClass from './pages/BookClass';
import Attendees from './pages/AttendeesPage';
import CreateClass from './pages/CreateClass';
import EditClassType from './pages/EditClassType';
import CreateClassType from './pages/CreateClassType';
import ClassPriceList from './pages/ClassPriceList';
import Contact from './pages/Contact';
import FooterNav from './components/Nav/FooterNav';
import NavigationBar from './components/Nav/NavigationBar';
import './App.css';

const queryClient = new QueryClient();

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ServiceWorkerUpdate />
      <AlertProvider>
        <BrowserRouter>
              <div>
                {/* Add a Navigation component here if you have one */}
                <NavigationBar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/userlanding" element={<UserLandingPage />} />
                  <Route path="/clublanding" element={<ClubLandingPage />} />
                  <Route path="/signin/:clubName" element={<SignIn />} />
                  <Route path="/signup/:clubName" element={<SignUp />} />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/members" element={<ClubMembers />} />
                  <Route path="/managefamily" element={<ManageFamily />} />
                  <Route path="/createclass" element={<CreateClass />} />
                  <Route path="/createclasstype" element={<CreateClassType />} />
                  <Route path="/editclasstype" element={<EditClassType />} />
                  <Route path="/searchclasses" element={<SearchClasses />} />
                  <Route path="/bookclasses" element={<BookClasses />} />
                  <Route path="/pricelist" element={<ClassPriceList />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/bookclass/:eventId" element={<BookClass />} />
                  <Route path="/attendees/:eventId" element={<Attendees />} />
                </Routes>
                <FooterNav />
              </div>
        </BrowserRouter>
      </AlertProvider>
    </QueryClientProvider>
  );
}

export default App;