import { Helmet } from 'react-helmet';
import { getFriendlyName } from '../helpers/ClubNames';
import { useParams } from 'react-router-dom';
import SignInForm from "../components/Auth/SignInForm";

export default function SignIn() {
  const { clubName } = useParams();
  const friendlyClubName = getFriendlyName(clubName ?? '');

  return (
    <>
      <Helmet>
        <title>Sign In | {friendlyClubName}</title>
      </Helmet>
      <SignInForm />
    </>
  );
}