// Libs
import { useFormik } from 'formik';
import { useNavigate} from 'react-router-dom';
// Api Requests
import { forgotPassword } from '../../api/Auth';
// Validations
import { forgotPasswordSchema } from '../../validations/ForgotPasswordSchema';
// Types
import { Stages } from '../../types/types';
// Material-UI
import { Avatar, Box, Button, Grid, TextField, Typography } from '@mui/material';
// Material-UI Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


interface ResetFormProps {
  switchStage: (newStage: Stages) => void;
  showAlert: (message: string, subMessage: string, status: 'success' | 'error') => void;
}

export default function ResetForm({ switchStage, showAlert }: ResetFormProps) {
  const navigate = useNavigate();

  function updateQueryString(email: string) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('email', email); // Directly set the email without manual encoding
    navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
  }

  const handleGoBack = (event: any) => {
    event.preventDefault();
    navigate(-1); // This will take the user back to the previous page
  };

  const sendRequest = async (values: {email: string}, resetForm: () => void) => {
    try {
      const response = await forgotPassword(values);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        updateQueryString(values.email);
        switchStage(Stages.VERIFY);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
        resetForm();
      }
    } catch (error) {
      showAlert('An error occurred', 'Please try again later', 'error');
    }
  }

  const formik = useFormik({
      initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      sendRequest(values, resetForm);
    },
  });
  
  return (
    <Box
       sx={{
       marginTop: 8,
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
    }}>
      <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h2" variant="h6">
        Enter Email Address
      </Typography>
          <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <TextField
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              autoComplete="username"
            />
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Request Reset Code
            </Button>
          </Box>
          </form>
          <Grid container>
            <Grid item xs>
              <Button 
                onClick={handleGoBack} 
                variant="text"
                disableRipple
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'none',
                  },
                }}
               >
               Go Back
              </Button>
            </Grid>
          </Grid>
        </Box>
  );
}