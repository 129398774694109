import React, { useEffect, useState } from 'react';
import { Snackbar, Button, SnackbarCloseReason } from '@mui/material';

const ServiceWorkerUpdate: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then((reg) => {

        // Immediately check if there's already a waiting service worker.
        if (reg.waiting) {
          setOpen(true);
        }

        reg.onupdatefound = () => {
          const newWorker = reg.installing;
          if (newWorker == null) {
            return;
          }
          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              // Show the Snackbar when a new service worker is installed and waiting
              setOpen(true);
            }
          };
        };
      }).catch(error => console.error(`Service worker registration failed: ${error}`));
    }
  }, []);

  const handleClose = (
    event: React.SyntheticEvent<any, Event> | Event, 
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleRefresh = () => {
    if ('serviceWorker' in navigator) {
      // First, check if there's a waiting service worker.
      const reg = navigator.serviceWorker.getRegistration();
      reg.then((registration) => {
        if (registration && registration.waiting) {
          // Listen for the controllerchange event on the navigator.serviceWorker
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
          });
  
          // Now, post the SKIP_WAITING message to the waiting service worker.
          registration.waiting.postMessage({type: 'SKIP_WAITING'});
          setOpen(false); // Close the snackbar alert
        } else if (navigator.serviceWorker.controller) {
          // If there's an active controller but no waiting service worker,
          // it might be safe to prompt for a manual refresh or automatically refresh.
          window.location.reload();
        }
      });
    }
  };
  

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message="A new version is available."
      action={
        <>
          <Button color="primary" size="small" onClick={handleRefresh}>
            REFRESH
          </Button>
          <Button color="primary" size="small" onClick={() => setOpen(false)}>
            DISMISS
          </Button>
        </>
      }
    />
  );
};

export default ServiceWorkerUpdate;