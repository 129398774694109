import { DateTime, Zone } from "luxon";

export function showFriendlyClassStartEnd(date: string, duration: any, timezone: string | Zone | undefined) {
  const start = DateTime.fromISO(date).setZone(timezone);
  const endDate = start.plus({ minutes: duration });
  const startTime = start.toFormat('HH:mm');
  const endTime = endDate.toFormat('HH:mm');

  return `${startTime} - ${endTime}`;
}

export function showFriendlyDate(date: string, timezone: string | Zone | undefined) {
  const dateObject = DateTime.fromISO(date).setZone(timezone);
  const d = dateObject.toFormat('EEE, LLL dd yyyy');
  return d;
}

export function getTodaysClasses(timezone: string, data: any[]) {
  const now = DateTime.local().setZone(timezone);
  const beginningOfDay = now.startOf('day');
  const endOfDay = now.endOf('day');

  const filteredData = data.filter((item: any) => {
    const itemDate = DateTime.fromISO(item.date);
    const itemEndDate = itemDate.plus({minutes: item.duration}); // Add duration to itemDate
    return itemEndDate >= beginningOfDay && itemDate <= endOfDay;
  });

  // Sort the filtered data by time
  const sortedData = filteredData.sort((a: any, b: any) => {
    const aDate = DateTime.fromISO(a.date);
    const bDate = DateTime.fromISO(b.date);
    return aDate.toMillis() - bDate.toMillis();
  });

  return sortedData;
}

export function getAllClassesGroupedByDate(timezone: string, data: any[]) {
  const now = DateTime.local().setZone(timezone);

  const filteredData = data.filter((item: any) => {
    const itemDate = DateTime.fromISO(item.date);
    const itemEndDate = itemDate.plus({minutes: item.duration}); // Add duration to itemDate
    return itemEndDate >= now;
  });

  // Sort the filtered data by time
  const sortedData = filteredData.sort((a: any, b: any) => {
    const aDate = DateTime.fromISO(a.date);
    const bDate = DateTime.fromISO(b.date);
    return aDate.toMillis() - bDate.toMillis();
  });

  // Group the filtered data by date
  const groupedData = sortedData.reduce((groups: Map<string, any[]>, item: any) => {
    const date = DateTime.fromISO(item.date).toFormat('ccc dd LLL yyyy'); // Get the date part of the DateTime
    if (!groups.has(date)) {
      groups.set(date, []);
    }
    const group = groups.get(date);
    if (group) {
      group.push(item);
    }
    return groups;
  }, new Map());

  // Convert the Map to an Array and sort by date
  const finalData = Array.from(groupedData.entries()).sort((a: any, b: any) => {
    return DateTime.fromFormat(a[0] as string, 'ccc dd LLL yyyy').toMillis() - DateTime.fromFormat(b[0] as string, 'ccc dd LLL yyyy').toMillis();
  });

  return finalData;
}

export function showFriendlyTime(date: string, timezone: string | Zone | undefined) {
  // Assuming date is in UTC, create a Luxon DateTime object
  const utcDateTime = DateTime.fromISO(date);

  // Convert the DateTime to the user's timezone
  const userDateTime = utcDateTime.setZone(timezone);

  // Get hours and minutes
  const hours = userDateTime.hour;
  const minutes = userDateTime.minute.toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export function internationalizePhoneNumber(phoneNumber: string) {
  if (phoneNumber.startsWith('0')) {
    return phoneNumber.replace('0', '+44');
  }
  return phoneNumber;
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};