import * as Yup from 'yup';
import { ukMobileRegex } from '../helpers/RegExPatterns';

export const createProfileSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'First Name Is Required')
      .required('First Name Is Required'),
    lastName: Yup.string()
      .min(2, 'Last Name Is Required')
      .required('Last Name Is Required'),
    clubName: Yup.string()
      .required('Club Name Is Required'),
    email: Yup.string()
      .required('Email Is Required'),
    telephone: Yup.string()
      .matches(ukMobileRegex, 'Telephone Number Invalid')
      .required('Telephone Is Required'),
    timezone: Yup.string()
      .required('Timezone Is Required'),
  });