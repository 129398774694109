// Libs
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
// Api Requests
import { userSignUp } from '../api/Users';
// Helpers
import { getFriendlyName } from '../helpers/ClubNames';
import { scrollToTop } from '../helpers/Utilities';
// Components
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Avatar, Button, Container, CssBaseline, TextField, Typography, InputAdornment, IconButton, Link, Grid, Box } from '@mui/material';
// Material-UI Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Form Validation
import { SignupSchema } from '../validations/SignUpSchema';

export default function SignUp() {
  const { clubName } = useParams();
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const friendlyClubName = getFriendlyName(clubName ?? '');

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      clubName: 'Devils Martial Arts',
      userType: 'member',
      telephone: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: SignupSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      handleSignUp(values, resetForm);
    },
  });

  // Show/Hide Password & Password Confirmation
  const [passwordVisibility, setPasswordVisibility] = React.useState({
    password: false,
    confirmation: false,
  });

  const togglePasswordVisibility = (field: 'password' | 'confirmation') => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignUp = async (data: any, resetForm: () => void) => {
    try {
      const response = await userSignUp(data);
      if (response.success) {
        scrollToTop();
        showAlert(response.success.message, response.success.subMessage, 'success');
        resetForm();
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }

    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  }
 
  return (
    <>
      <Helmet>
        <title>Sign Up | {friendlyClubName}</title>
      </Helmet>
      <Container component="main" maxWidth="xs">
        {alert && (
          <AlertMessage />
        )}
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ m: 1, width: 66, height: 66 }}
            src={`https://bookmeinapp.s3.eu-west-1.amazonaws.com/${clubName}/logo.png`}
            alt="Club Logo"
          />
          <Typography component="h2" variant="h6">
            Create Your Account Today
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    value={formik.values.firstName}
                    name="firstName"
                    required
                    fullWidth
                    id="signUpFirstName"
                    label="First Name"
                    autoFocus />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    value={formik.values.lastName}
                    required
                    fullWidth
                    id="signUpLastName"
                    label="Last Name"
                    name="lastName" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.clubName && Boolean(formik.errors.clubName)}
                    helperText={formik.touched.clubName && formik.errors.clubName}
                    value={formik.values.clubName}
                    required
                    fullWidth
                    id="signUpClubName"
                    label="Club Name"
                    name="clubName" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.userType && Boolean(formik.errors.userType)}
                    helperText={formik.touched.userType && formik.errors.userType}
                    value={formik.values.userType}
                    required
                    fullWidth
                    id="signUpUserType"
                    label="User Type"
                    name="userType" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    required
                    fullWidth
                    id="signUpEmail"
                    label="Email Address"
                    autoComplete="username"
                    name="email" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                    helperText={formik.touched.telephone && formik.errors.telephone}
                    value={formik.values.telephone}
                    required
                    fullWidth
                    id="signUpTelephone"
                    label="Telephone Number"
                    name="telephone" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    value={formik.values.password}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={passwordVisibility.password ? "text" : "password"}
                    id="signUpPassword"
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => togglePasswordVisibility('password')}
                            onMouseDown={handleMouseDown}
                            edge="end">
                            {passwordVisibility.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                    value={formik.values.passwordConfirmation}
                    required
                    fullWidth
                    name="passwordConfirmation"
                    label="Confirm Password"
                    type={passwordVisibility.confirmation ? "text" : "password"}
                    id="signUpConfirmationPassword"
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => togglePasswordVisibility('confirmation')}
                            onMouseDown={handleMouseDown}
                            edge="end">
                            {passwordVisibility.confirmation ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link href={`/signin/${clubName}`} variant="body2" onClick={(e) => e.stopPropagation()}>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
}