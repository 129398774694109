
// Libs
import { useFormik } from 'formik';
import { useNavigate} from 'react-router-dom';
// Api Requests
import { verfifyResetCode } from '../../api/Auth';
// Types
import { Stages } from '../../types/types';
// Validations
import { confirmCodeSchema } from '../../validations/CodeFormSchema';
// Material-UI
import { Avatar, Box, Button, Grid, TextField, Typography } from '@mui/material';
// Material-UI Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface CodeFormProps {
  switchStage: (newStage: Stages) => void;
  showAlert: (message: string, subMessage: string, status: 'success' | 'error') => void;
}

export default function ResetForm({ switchStage, showAlert }: CodeFormProps) {
  const navigate = useNavigate();
  
  const handleGoHome = (event: any) => {
    event.preventDefault();
    navigate('/');
  };

  const sendRequest = async (values: {code: string}, resetForm: () => void) => {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email'); // Extract email from the URL

    if (!email) {
      console.error('Email is missing from the URL');
      return;
    }

    try {
      const response = await verfifyResetCode(values, email);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        switchStage(Stages.RESET);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
        resetForm();
      }
    } catch (error) {
      showAlert('An error occurred', 'Please try again later', 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
    code: '',
  },
    validationSchema: confirmCodeSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      sendRequest(values, resetForm);
    },
  });

  return (
    <Box
           sx={{
           marginTop: 8,
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
        }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h6">
            Enter Verification Code
          </Typography>
              <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                  value={formik.values.code}
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Verification Code"
                  name="code"
                  autoFocus
                />
                <Button
                  type="submit"
                  disabled={formik.isSubmitting}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit Reset Code
                </Button>
              </Box>
              </form>
              <Grid container>
                <Grid item xs>
                  <Button 
                    onClick={handleGoHome} 
                    variant="text"
                    disableRipple
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'none',
                      },
                    }}
                   >
                   Go Home
                  </Button>
                </Grid>
              </Grid>
    </Box>
  );
}