import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { User, SignInCredentials } from '../types/types';

async function signInUser(credentials: SignInCredentials): Promise<User> {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/api/login`;
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
   });
   const jsonData = await response.json();
  
    if (!response.ok) {
      throw new Error(JSON.stringify({
        message: jsonData.error.message,
        subMessage: jsonData.error.subMessage,
      }));
    }
  
    localStorage.setItem('userData', JSON.stringify(jsonData.user)); // Persist user data
    return jsonData.user;
}

function signOutUser() {
  localStorage.removeItem('userData');
}

export function useAuth() {
  const queryClient = useQueryClient();
  
  const { data: user, isLoading: isUserLoading, isError: isUserError } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = localStorage.getItem('userData');
      return userData ? JSON.parse(userData) : null;
    },
  });

  const signInMutation = useMutation<User, Error, SignInCredentials>({
    mutationFn: signInUser,
    onSuccess: (userData) => {
      queryClient.setQueryData(['user'], userData);
    },
    // Not handling onError here since AlertContext is removed
  });
  
  const signOut = () => {
    signOutUser();
    queryClient.setQueryData(['user'], null); // Clear user data in cache
  };

  // Function to update the user data in both React Query cache and localStorage
  const updateUserData = (updatedUserData: User) => {
    localStorage.setItem('userData', JSON.stringify(updatedUserData)); // Optionally update localStorage
    queryClient.setQueryData(['user'], updatedUserData); // Update React Query cache
  };

  const updateUserFamilyMembers = (updatedFamilyMembers: any) => {
    queryClient.setQueryData<User>(['user'], (oldUser) => {
      // Check if the oldUser data exists to ensure TypeScript type safety
      if (!oldUser) {
        return null;
      }
      // Create a new user object with updated familyMembers property
      const updatedUser = { ...oldUser, familyMembers: updatedFamilyMembers };
      // Update localStorage with the new user data
      localStorage.setItem('userData', JSON.stringify(updatedUser));
      
      // Return a new user object with updated familyMembers property
      return updatedUser;
    });
  };
  
  return { user, isUserLoading, isUserError, signIn: signInMutation.mutate, signOut, updateUserData, updateUserFamilyMembers };
}
