// Libs
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Hooks
import { useAuth } from "../hooks/useAuth";
// Components
import BasicMap from "../components/Maps/BasicMap";
// Material-UI
import { Box, Grid, Container, IconButton, Paper, Typography } from '@mui/material';
// Material-UI Icons
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import WebIcon from '@mui/icons-material/Web';

export default function Contact() {
  const navigate = useNavigate();
  const { user, isUserLoading } = useAuth();

  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
      if (user?.dataValues?.user_type !== 'member') {
        navigate('/searchclasses'); // Redirect to club search classes page if not a member user
        return;
      }
    }
  }, [user, isUserLoading, navigate]);

  return (    
    <>
    <Helmet>
      <title>{`${user?.dataValues?.club_name || ''} | About Us`}</title>
    </Helmet>
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      <Box textAlign="center" p={3}>
        <Typography variant="h5" gutterBottom>Contact Us</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4} md={2}>
              <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                <IconButton href="https://www.devilsmartialarts.com/index.html" target="_blank" rel="noopener noreferrer">
                  <WebIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={4} md={2}>
                <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                <IconButton href="https://www.facebook.com/groups/6703816526" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon />
                </IconButton>
                </Paper>
            </Grid>
            <Grid item xs={4} md={2}>
                <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                <IconButton href="https://chat.whatsapp.com/Dqp82YMXa3hIFpS7ydesSy" target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon />
                </IconButton>
                </Paper>
            </Grid>
            <Grid item xs={4} md={2}>
                <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                <IconButton href="mailto:rob@devilsmartialarts.com">
                    <MailOutlineIcon />
                </IconButton>
                </Paper>
            </Grid>
            <Grid item xs={4} md={2}>
                <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                <IconButton href="tel:+447799333821">
                    <CallIcon />
                </IconButton>
                </Paper>
            </Grid>
            <Grid item xs={4} md={2}>
                <Paper elevation={3} sx={{ p: 2, mb: 2, textAlign: 'center' }}>
                <IconButton href="https://www.instagram.com/robdevilsma/" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon />
                </IconButton>
                </Paper>
            </Grid>
          </Grid>
          {/* Google Maps */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>Find Us</Typography>
            <Typography gutterBottom>Unit 24, Fern Close, Pen Y Fan Industrial Estate, Oakdale NP11 3EH</Typography>
              <Paper elevation={3}>
                <BasicMap lat={51.68782949796694} lng={-3.1607741340409996} />
              </Paper>
          </Grid>
      </Box>
    </Container>
    </>
  );
}