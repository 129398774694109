// Helpers
import { internationalizePhoneNumber } from "../../helpers/Utilities";
// Material UI
import { Avatar, Box, IconButton, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material';
// Material UI Icons
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function MemberList({member}: {member: any}) {
  return ( 
    <Grid item xs={12} md={6}>
      <Box key={member.id}>
        <List sx={{paddingRight: 0, paddingLeft: 0}}>
          <ListItem sx={{ paddingRight: 0, paddingLeft: 0 }}>
            <ListItemAvatar>
              <Avatar src={member.member_profile_image_url}>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{member.first_name} {member.last_name}</ListItemText>
            <ListItemSecondaryAction>
              <a href={`tel:${internationalizePhoneNumber(member.telephone)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <IconButton edge="end" aria-label="phone" sx={{ marginRight: { xs: 1, sm: 2, md: 3 } }}>
                  <LocalPhoneIcon />
                </IconButton>
              </a>
              <a href={`sms:${internationalizePhoneNumber(member.telephone)}`}>
                <IconButton>
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </a>
              <a href={`mailto:${member.email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                <IconButton edge="end" aria-label="email" sx={{ marginRight: { xs: 1, sm: 2, md: 3 } }}>
                  <MailOutlineIcon />
                </IconButton>
              </a>
           </ListItemSecondaryAction>
         </ListItem>
        </List>
      </Box>
  </Grid>
  );
}