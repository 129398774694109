// Libs
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
// Hooks
import { useAuth } from "../hooks/useAuth";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { retrieveClubMembers } from "../api/Users";
// Components
import Loading from "../components/Alerts/Loading";
import MemberList from "../components/Members/MemberList";
import ScrollToTop from '../components/Scroll/ScrollToTop';
import PullToRefresh from "../components/Refresh/PullToRefresh";
// Material-UI
import { Box, Button, Card, CardContent, Container, CssBaseline, FormControl, Grid, TextField, Typography } from "@mui/material";
// Material-UI Icons
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

export default function ClubMembers() {
  const { user, isUserLoading } = useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);
  
  const queryClient = useQueryClient();
  const { data: clubMemberData, isLoading } = useQuery({
    queryKey: ['clubMemberData', user?.dataValues?.club_name],
    queryFn: () => retrieveClubMembers(user?.dataValues?.club_name),
    enabled: !!user?.dataValues?.club_name
  });
  
  const filteredMembers = clubMemberData && clubMemberData.length > 0
  ? clubMemberData.reduce((acc: any[], member: { first_name: string; last_name: string; }) => {
      // Check if the member's name matches the search term
      const nameMatch = searchTerm
        ? member.first_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
          member.last_name.toLowerCase().includes(searchTerm.toLowerCase())
        : true;
    
      // If there's a match, add the member to the accumulator
      if (nameMatch) {
        acc.push(member);
      }
    
      return acc;
    }, [])
  : [];

  const resetFilter = () => {
    setSearchTerm('');
  };

  const handleRefresh = async () => {
    // Start both the timeout and invalidation process simultaneously
    // Artificial delay to show the loading state so the user knows something is happening
    const timeoutPromise = new Promise(resolve => setTimeout(resolve, 1000)); // Artificial delay
    const invalidationPromise = queryClient.invalidateQueries({
      queryKey: ['clubMemberData', user?.dataValues?.club_name]
    });
  
    // Wait for both promises to complete
    await Promise.all([timeoutPromise, invalidationPromise]);
  };

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }
  
  return (
    <>
    <Helmet>
      <title>{`${user?.dataValues?.club_name} | Member List`}</title>
    </Helmet>
    <PullToRefresh onRefresh={handleRefresh}>
    <div id="back-to-top-anchor" />
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      <CssBaseline />
      <Typography sx={{ fontWeight: '500', marginTop: '15px', textAlign: 'center' }}>
        Member List ({filteredMembers.length})
      </Typography>
      
      <Box sx={{ padding: '20px' }}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="member-name"
                label="Member Name"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
              />
            </FormControl>
        </Grid>
      </Box>

      <Box sx={{ padding: '20px' }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" onClick={resetFilter}>Clear Filter</Button>
          </Box>
        </Grid>
      </Box>

      {filteredMembers?.length === 0 ? (
          <Box textAlign={'center'} sx={{marginTop: '15px'}}>
          <Card >
            <CardContent>
              <Box>
                <Typography sx={{fontWeight: '600'}}>No Members Found</Typography>
                <LocalActivityIcon fontSize="large"/>
              </Box>
              <Button variant="contained" onClick={() => navigate('/')}>Home</Button>
            </CardContent>
          </Card>
        </Box>
        ) : (
          <>
          {filteredMembers?.map((member: any) => (
            <MemberList key={member.id} member={member} />
          ))}
          <ScrollToTop />
          </>
        )}
    </Container>
    </PullToRefresh>
    </>
  );
}