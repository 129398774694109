// Libs
import React from 'react';
import { useFormik } from 'formik';
import { useNavigate} from 'react-router-dom';
// Api Requests
import { changePassword } from '../../api/Auth';
// Validations
import { newPasswordSchema } from '../../validations/NewPasswordSchema';
// Types
import { Stages } from '../../types/types';
// Material-UI
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
//Material-UI Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


interface NewPasswordFormProps {
  switchStage: (newStage: Stages) => void;
  showAlert: (message: string, subMessage: string, status: 'success' | 'error') => void;
}

export default function NewPasswordForm({ switchStage, showAlert }: NewPasswordFormProps) {
    const navigate = useNavigate();
    
    const handleGoHome = (event: any) => {
      event.preventDefault();
      navigate('/');
    };

    // Show/Hide Password & Password Confirmation
    const [passwordVisibility, setPasswordVisibility] = React.useState({
      password: false,
      confirmation: false,
    });

    const togglePasswordVisibility = (field: 'password' | 'confirmation') => {
      setPasswordVisibility(prevState => ({
        ...prevState,
        [field]: !prevState[field],
      }));
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const sendRequest = async (values: {password: string, passwordConfirmation: string}, resetForm: () => void) => {
        const searchParams = new URLSearchParams(window.location.search);
        const email = searchParams.get('email'); // Extract email from the URL
    
        if (!email) {
          console.error('Email is missing from the URL');
          return;
        }

        try {
          const response = await changePassword(values, email);
          if (response.success) {
            showAlert(response.success.message, response.success.subMessage, 'success');
            navigate(`/signin/${response.clubName}`);
          }
          if (response.error) {
            showAlert(response.error.message, response.error.subMessage, 'error');
            resetForm();
          }

        } catch (error) {
          showAlert('An error occurred', 'Please try again later', 'error');
        }
    };
    
    const formik = useFormik({
      initialValues: {
        password: '',
        passwordConfirmation: '',
      },
        validationSchema: newPasswordSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
          sendRequest(values, resetForm);
        },
    });

    return (
        <Box
               sx={{
               marginTop: 8,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}>
              <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h2" variant="h6">
                Enter New Password
              </Typography>
                  <form onSubmit={formik.handleSubmit}>
                  <Box sx={{ mt: 1 }}>
                  <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    value={formik.values.password}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={passwordVisibility.password ? "text" : "password"}
                    id="signUpPassword"
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => togglePasswordVisibility('password')}
                            onMouseDown={handleMouseDown}
                            edge="end">
                            {passwordVisibility.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} />
                    </Box>
                    <Box sx={{ mt:1 }}>
                    <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                    value={formik.values.passwordConfirmation}
                    required
                    fullWidth
                    name="passwordConfirmation"
                    label="Confirm Password"
                    type={passwordVisibility.confirmation ? "text" : "password"}
                    id="signUpConfirmationPassword"
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => togglePasswordVisibility('confirmation')}
                            onMouseDown={handleMouseDown}
                            edge="end">
                            {passwordVisibility.confirmation ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }} />
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting}
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Save New Password
                    </Button>
                  </Box>
                  </form>
                  <Grid container>
                    <Grid item xs>
                      <Button 
                        onClick={handleGoHome} 
                        variant="text"
                        disableRipple
                        sx={{
                          textDecoration: 'none',
                          color: 'inherit',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            textDecoration: 'none',
                          },
                        }}
                       >
                       Go Home
                      </Button>
                    </Grid>
                  </Grid>
        </Box>
      );
}