// Libs
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useAuth } from '../hooks/useAuth';
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { getEventTypeByUserId } from '../api/EventTypes';
// Services
import { createNewEvent } from '../api/Events';
// Helpers
import { scrollToTop } from '../helpers/Utilities';
// Types
import { EventType } from '../types/types';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { Box, Button, Container, CssBaseline, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
// Material-UI Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// Material-UI Lab
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

// Yup validation schema
const createClassSchema = Yup.object().shape({
  newEventName: Yup.string()
    .required('Class Name Is Required'),
  newEventDescription: Yup.string()
    .required('Class Description Is Required'),
  newEventDuration: Yup.string()
    .required('Class Duration Is Required'),
  newEventLimit: Yup.number()
    .typeError('Class Limit must be a number')
    .required('Class Limit Is Required')
    .min(1, 'Class Limit must be greater than 0'),
  date: Yup.mixed()
    .test('is-date', 'Class Date Is Required', value => DateTime.isDateTime(value)),
  repeatEvent: Yup.string()
    .required('Class Repeat Is Required'),
  repeatEventAmount: Yup.number()
    .when('repeatEvent', {
      is: (v: string) => v === 'daily' || v === 'weekly',
      then(schema) {
        return schema.min(1, 'Repeat length must be greater than 0');
      },
    }),
});

function updateClassDescription(setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, description: string) {
  setFieldValue("newEventDescription", description);
}

export default function CreateClass() {
  const { user, isUserLoading } = useAuth();
  const { alert } = useAlert();
  const showAlert = useShowAlert();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);

  // useQuery hook from react-query
  const { data: eventTypes, isLoading } = useQuery({
    queryKey: ['clubEventTypes', user?.dataValues?.id],
    queryFn: () => getEventTypeByUserId(user?.dataValues?.id),
    enabled: !!user?.dataValues?.id
  });

  // useEffect to set eventTypes when clubEventTypes has a response
  useEffect(() => {
    if (eventTypes) {
      setClassTypeData(eventTypes);
    }
  }, [eventTypes]);
  
  // Stores all event_type and used in dropdown
  const [classTypeData, setClassTypeData] = useState<EventType[]>([]);
  // Store currently selected class details
  const [selectedClass, setSelectedClass] = useState<EventType | null>(null);
  const [repeatClassCadence, setRepeatClassCadence] = useState('');
  const [showRepeatClass, setShowRepeatClass] = useState(false);
  
  const formik = useFormik({
    initialValues: {
    userId: user?.dataValues?.id || '',
    newEventName: '',
    newEventDescription: '',
    newEventDuration: '',
    newEventLimit: 0,
    clubName: user?.dataValues?.club_name || 'Devils Martial Arts',
    date: DateTime.now().setZone(user?.dataValues?.timezone || 'Europe/London'),
    repeatEvent: '',
    repeatEventAmount: 0,
    epochDate: DateTime.now().setZone(user?.dataValues?.timezone || 'Europe/London').toUTC().toMillis(),
  },
    validationSchema: createClassSchema,
    onSubmit: (values, { resetForm }) => {
      handleCreateNewEvent(values, resetForm);
    },
  });
  
  // Update Class Description when changing Class Name
  useEffect(() => {
    if (selectedClass && selectedClass.description) {
      updateClassDescription(formik.setFieldValue, selectedClass.description);
    }
  }, [selectedClass]);
  
  const handleCreateNewEvent = async (data: any, resetForm: () => void) => {
    try {
      const response = await createNewEvent(data);
      if (response.success) {
        scrollToTop();
        showAlert(response.success.message, response.success.subMessage, 'success');
        setShowRepeatClass(false);
        resetForm();
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };
  
  const updateRepeatClassCadence = (newValue: string) => {
    let cadence;
    switch (newValue) {
      case 'daily':
        cadence = 'days';
        setShowRepeatClass(true);
        break;
      case 'weekly':
        cadence = 'weeks';
        setShowRepeatClass(true);
        break;
      default:
        cadence = '';
        setShowRepeatClass(false);
        break;
    }

    formik.setFieldValue('repeatEvent', newValue);
    // setRepeatClass(newValue);
    setRepeatClassCadence(cadence);
  };

  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

    return (
      <Container component="main" maxWidth="sm" sx={{ pt: '64px' }}>
        <CssBaseline />
        {alert && (
          <AlertMessage />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 800,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
        <Typography component="h2" variant="h6">
          Create New Class
        </Typography>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1}}>
      
            {/* Class Name */}
            <FormControl fullWidth error={formik.touched.newEventName && Boolean(formik.errors.newEventName)}>
              <InputLabel id="newEventName-label">Class Name</InputLabel>
              <Select
                labelId="newEventName-label"
                id="newEventName"
                label="Class Name"
                name="newEventName"
                displayEmpty
                value={formik.values.newEventName}
                onChange={(event) => {
                  formik.handleChange(event);
                  const selectedClass = classTypeData.find(classType => classType.name === event.target.value);
                  setSelectedClass(selectedClass || null);
                }}
               >
                {classTypeData && classTypeData.length > 0 && (
                  classTypeData.map((classType) => (
                    <MenuItem key={classType.id} value={classType.name}>
                      {classType.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>{formik.touched.newEventName && formik.errors.newEventName}</FormHelperText>
            </FormControl>

            {/* Class Description */}
            <FormControl sx={{ mt: 2 }} fullWidth>
              <TextField
                id="newEventDescription"
                label="Class Description"
                name="newEventDescription"
                multiline
                rows={4}
                value={formik.values.newEventDescription}
                onChange={formik.handleChange}
                error={formik.touched.newEventDescription && Boolean(formik.errors.newEventDescription)} // Set the error state here
                helperText={formik.touched.newEventDescription && formik.errors.newEventDescription} // Move the helper text to the TextField
              />
            </FormControl>

            {/* Class Duration */}
            <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.newEventDuration && Boolean(formik.errors.newEventDuration)}>
              <InputLabel id="newEventDuration-label">Class Duration</InputLabel>
              <Select
                labelId="newEventDuration-label"
                id="newEventDuration"
                fullWidth
                displayEmpty
                label="Class Duration"
                name="newEventDuration"
                value={formik.values.newEventDuration}
                onChange={formik.handleChange}
              >
                <MenuItem value={15}>15 Mins</MenuItem>
                <MenuItem value={30}>30 Mins</MenuItem>
                <MenuItem value={45}>45 Mins</MenuItem>
                <MenuItem value={60}>60 Mins</MenuItem>
                <MenuItem value={90}>90 Mins</MenuItem>
                <MenuItem value={120}>2 Hours</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.newEventDuration && formik.errors.newEventDuration}</FormHelperText>
            </FormControl>

            {/* Class Limit */}
            <FormControl sx={{ mt: 2 }} fullWidth>
              <TextField
                id="newEventLimit"
                label="Class Limit"
                fullWidth
                value={formik.values.newEventLimit}
                onChange={formik.handleChange}
                error={formik.touched.newEventLimit && Boolean(formik.errors.newEventLimit)}
                helperText={formik.touched.newEventLimit && formik.errors.newEventLimit}
                InputProps={{
                  inputProps: { min: 0 }, // This ensures the number doesn't go below 0
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        onClick={() => {
                          const currentValue = parseInt(formik.values.newEventLimit.toString(), 10) || 0;
                          if (currentValue > 0) { // Only decrement if the current value is greater than 0
                            formik.setFieldValue('newEventLimit', (currentValue - 1).toString());
                          }
                          }}
                        edge="end"
                        aria-label="decrement"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => {
                          const currentValue = parseInt(formik.values.newEventLimit.toString(), 10) || 0;
                          formik.setFieldValue('newEventLimit', (currentValue + 1).toString());
                        }}
                        edge="end"
                        aria-label="increment"
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            {/* Event Date/Time */}
            <FormControl sx={{ mt: 2 }} fullWidth>
            <DateTimePicker
              label="Class Date"
              value={formik.values.date}
                onChange={(value): void => {
                if (value) {
                  formik.setFieldValue('date', value.setZone(user?.dataValues?.timezone || 'Europe/London'));
                  formik.setFieldValue('epochDate', value.setZone(user?.dataValues?.timezone || 'Europe/London').toUTC().toMillis());
                }
              }}
            />
            </FormControl>

            {/* Repeat Class */}
            <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.repeatEvent && Boolean(formik.errors.repeatEvent)}>
              <InputLabel id="repeatEvent-label">Repeat Class</InputLabel>
              <Select
                labelId="repeatEvent-label"
                id="repeatEvent"
                displayEmpty
                label="Repeat Class"
                name="repeatEvent"
                value={formik.values.repeatEvent}
                onChange={(event) => {
                  formik.handleChange(event); // Call Formik's handleChange
                  updateRepeatClassCadence(event.target.value); // Call updateRepeatClassCadence with the new value
                }}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
              </Select>
              <FormHelperText>{formik.touched.repeatEvent && formik.errors.repeatEvent}</FormHelperText>
            </FormControl>

            {showRepeatClass && (
            // Repeat Class Cycle
            <FormControl sx={{ mt: 2 }} fullWidth>
              <TextField
                id="repeatEventAmount"
                fullWidth
                label="Repeat Length"
                value={`${formik.values.repeatEventAmount.toString()} ${repeatClassCadence}`}
                onChange={formik.handleChange}
                error={formik.touched.repeatEventAmount && Boolean(formik.errors.repeatEventAmount)}
                helperText={formik.touched.repeatEventAmount && formik.errors.repeatEventAmount}
                InputProps={{
                  inputProps: { min: 0 }, // This ensures the number doesn't go below 0
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        onClick={() => {
                          const currentValue = parseInt(formik.values.repeatEventAmount.toString(), 10) || 0;
                          if (currentValue > 0) {
                            formik.setFieldValue('repeatEventAmount', (currentValue - 1).toString());
                          }
                        }}
                        edge="end"
                        aria-label="decrement"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => {
                          const currentValue = parseInt(formik.values.repeatEventAmount.toString(), 10) || 0;
                          formik.setFieldValue('repeatEventAmount', (currentValue + 1).toString());
                        }}
                        edge="end"
                        aria-label="increment"
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            )}
          </Box>
          {/* Create Class Button */}
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={formik.isSubmitting}
          >
            Create Class
          </Button>
          </Box>
        </form>
        </LocalizationProvider>
      </Box>
      </Container>  
    );
}