import { Box, Modal, Typography } from '@mui/material';

export default function PrivacyPolicyModal({ modalOpen, handleCloseModal }: { modalOpen: boolean, handleCloseModal: () => void }) {

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="privacy-policy-modal-title"
      aria-describedby="privacy-policy-modal-description"
    >
    <Box
      sx={{
        position: 'absolute',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
    {/* Add your privacy policy text here */}
    <Typography id="privacy-policy-modal-title" variant="h6" component="h2">
      Adding a family member
    </Typography>
    <Typography id="privacy-policy-modal-description" sx={{ mt: 2 }}>
      By checking this box you are confirming the following:
      <br /><br />
      1) That you are the persons legal and lawful guardian
      <br /><br />
      2) Consenting to their first and last name being shared with the club and stored in a database by BookMeIn
    </Typography>
  </Box>
  </Modal>
 );
}