import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '../types/types';

const useClubRedirect = (user: User | null, isUserLoading: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoading) {
      if (!user) {
        navigate('/');
        return;
      }
      if (user?.dataValues?.user_type !== 'club') {
        navigate('/userlanding');
        return;
      }
    }
  }, [user, isUserLoading, navigate]);
};

export default useClubRedirect;
