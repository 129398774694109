const clubNamesMap = [
    { name: 'devilsmartialarts', friendlyName: 'Devils Martial Arts' },
    // Add more clubs as needed
  ];

export const getFriendlyName = (clubIdentifier: string) => {
  const club = clubNamesMap.find(club => club.name === clubIdentifier);
  return club ? club.friendlyName : 'Unknown Club';
};

export const getClubNameToLowerCase = (club_name: string) => {
  // Take user.club_name and lowercase all letters and join with no spaces
  if (!club_name) {
    return '';
  }
  return club_name.toLowerCase().split(' ').join('');
}