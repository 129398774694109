import CloseIcon from '@mui/icons-material/Close';
import { Slide, Alert, Box, Typography, IconButton, AlertColor } from '@mui/material';
import { useAlert } from '../../AlertContext'; // Import useAlert hook
import { useEffect } from 'react';


const AlertMessage = () => {
  const { alert, hideAlert } = useAlert();

  useEffect(() => {
    if (alert) {
      // Set a timer to hide the alert after 3 seconds
      const timer = setTimeout(() => {
        hideAlert();
      }, 3000); // 3000 milliseconds = 3 seconds

      // Cleanup function to clear the timer if the alert is closed manually before 3 seconds
      return () => clearTimeout(timer);
    }
  }, [alert, hideAlert]);

  if (!alert) {
    return null;
  }

  const { status, message, subMessage } = alert as { status: string, message: string, subMessage: string };

  return (
    <Box width="100%" bgcolor="white" height={100}>
      <Slide direction="down" in={!!alert}>
        <Alert
          severity={status as AlertColor}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => hideAlert()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="body1" fontWeight="medium">
            {message}
          </Typography>
          {subMessage && (
            <Typography variant="body2" style={{ marginTop: 8 }}>
              {subMessage}
            </Typography>
          )}
        </Alert>
      </Slide>
    </Box>
  );
};

export default AlertMessage;