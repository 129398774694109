import GoogleMapReact from 'google-map-react';
// Material-UI Icons
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';

const MartialArtsMarker = ({ lat, lng }: { lat: number, lng: number }) => (
    <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
      <SportsMartialArtsIcon style={{ color: 'black', fontSize: 30 }} />
    </div>
  );
export default function BasicMap({ lat, lng }: { lat: number, lng: number }) {
  const defaultProps = {
    center: { lat: lat, lng: lng },
    zoom: 12
  };

  return (
    // Set the size of the map container
    <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}` }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
            <MartialArtsMarker
              lat={defaultProps.center.lat}
              lng={defaultProps.center.lng}
            />
        </GoogleMapReact>
    </div>
  );

};