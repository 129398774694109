export type FamilyMember = {
  id: string;
  first_name: string;
  last_name: string;
  user_id: string;
  consent_given: boolean;
}

export type User = {
  dataValues: any;
  id: string;
  first_name: string;
  last_name: string;
  user_type: 'club' | 'member';
  club_name: string;
  email: string;
  telephone: string;
  timezone: string;
  familyMembers: FamilyMember[];
} | null;

export type SignInCredentials = {
  signInEmail: string;
  signInPassword: string;
}

export type Event = {
  id: number;
  name: string;
  description: string;
  event_limit: number;
  date: string;
  duration: string;
  user_id: number;
  club_name: string;
  spaces_booked: number;
  repeat_event_id: string;
  epoch_date: number;
};

export type EventType = {
  id: number;
  name: string;
  description: string;
};

export type Booking = {
  id: number;
  user_id: string;
  family_member_id: string;
}

export type AlertContent = {
  message: string;
  subMessage: string;
  status: 'success' | 'error';
}

export type CancelClassDialog = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

export type DeleteClassTypeDialog = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

export type FamilyMemberCancelItem = {
  id: string
}

export type CancelClassItem = {
  user_id: number;
  family_member_id?: string;
  family_member_details?: FamilyMemberCancelItem;
  event_id: number;
  club_name: string;
}

export enum Stages {
  REQUEST_LINK = 'REQUEST_LINK',
  VERIFY = 'VERIFY',
  RESET = 'RESET',
};
